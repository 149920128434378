import cx from 'classnames';

const fossilIcon = ({
    fontSize = 25, className = '', iconContent = '', width = 25, height = 25,
}) => (
    <>
        <span className={cx('search-icon text-neutral-black', className)} />
        <style jsx>
            {`
                        .search-icon:before {
                            display: block;
                            content: ${iconContent || '"\\e955"'};
                            font-family: var(--icons-urbanicon-fossil-theme);
                            font-size: ${fontSize}px;
                            line-height: ${fontSize}px;
                            width: ${width}px;
                            height: ${height}px;
                        }
                        
                    `}
        </style>
    </>
);

export default fossilIcon;
